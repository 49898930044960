import $ from "jquery";

import Cookies from "js-cookie";

import config from "./config";
import { csrfSafeMethod } from "../core/utils";

const containerTags = [
  "a",
  "p",
  "div",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "button",
  "li",
  "label",
  "td",
  "th",
  "span",
  "small",
  "strong",
  "option",
];

function translateTextInsideContainers(sourceLanguage, targetLanguage) {
  const elementsToTranslate = document.querySelectorAll(containerTags.join(", "));

  elementsToTranslate.forEach((element) => {
    if (element.classList.contains("ignore")) {
      return; // Skip translation for elements with the 'ignore' class
    }
    const originalTextNodes = Array.from(element.childNodes).filter(
      (node) => node.nodeType === Node.TEXT_NODE,
    );
    const originalText = originalTextNodes.map((node) => node.textContent).join("");

    // Function to check if gapi.client is defined and execute translation
    const executeTranslation = () => {
      if (!window.gapi || !window.gapi.client) {
        setTimeout(executeTranslation, 100); // Retry after 100ms
        return;
      }

      // Make a request to the Google Cloud Translation API
      window.gapi.client
        .request({
          path: "/language/translate/v2",
          method: "POST",
          body: {
            q: originalText,
            target: targetLanguage,
            format: "text",
            source: sourceLanguage,
          },
        })
        .then(
          (response) => {
            const translation = response.result.data.translations[0].translatedText;
            const translatedTextNode = document.createTextNode(translation);

            originalTextNodes.forEach((node) => node.remove());
            element.appendChild(translatedTextNode);
          },
          (error) => {
            window.Rollbar.error("Translation error:", error);
          },
        );
    };
    executeTranslation(); // Start the translation process
  });

  // Translate placeholder text for input and textarea elements

  const translatePlaceholder = (element) => {
    const placeholder = element.getAttribute("placeholder");
    if (placeholder) {
      // Function to check if gapi.client is defined and execute placeholder translation
      const executePlaceholderTranslation = () => {
        if (!window.gapi.client) {
          setTimeout(executePlaceholderTranslation, 100); // Retry after 100ms
          return;
        }

        // Make a request to the Google Cloud Translation API to translate the placeholder text
        window.gapi.client
          .request({
            path: "/language/translate/v2",
            method: "POST",
            body: {
              q: placeholder,
              source: sourceLanguage,
              target: targetLanguage,
            },
          })
          .then(
            (response) => {
              const translatedPlaceholder =
                response.result.data.translations[0].translatedText;
              element.setAttribute("placeholder", translatedPlaceholder);
            },
            (error) => {
              window.Rollbar.error("Translation error:", error);
            },
          );
      };

      executePlaceholderTranslation(); // Start the placeholder translation process
    }
  };

  document.querySelectorAll("input").forEach((inputElement) => {
    translatePlaceholder(inputElement);
  });

  document.querySelectorAll("textarea").forEach((textareaElement) => {
    translatePlaceholder(textareaElement);
  });
}

async function initClient() {
  await window.gapi.client
    .init({
      apiKey: config.googleCloudTranslationApiKey,
      discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/translate/v2/rest"],
    })
    .then(
      () => {
        if (config.debug) {
          console.log("Google Cloud Translation API loaded");
        }
      },
      (error) => {
        window.Rollbar.error("Error loading Google Cloud Translation API:", error);
      },
    );
}

export function gapiOnload() {
  window.gapi.load("client", initClient);
}

export function initUpdatePreferredLanguage() {
  const csrfToken = Cookies.get("csrftoken");

  let lang = config.preferredLanguage;

  $("#languageMenu a").on("click", (event) => {
    event.preventDefault();

    lang = event.currentTarget.dataset.isoCode;

    $.ajax({
      url: config.updatePreferredLanguageUrl,
      type: "POST",
      beforeSend: function (xhr, settings) {
        if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
          xhr.setRequestHeader("X-CSRFToken", csrfToken);
        }
      },
      data: {
        preferred_language: lang,
      },
      success: function (data) {
        if (data.success) {
          // Language update successful
          window.location.reload();
        } else {
          // Language update failed
          // Perform any error handling or display error message
          window.Rollbar.error("Error updating preferred language!");
        }
      },
    });
  });

  if (lang !== config.englishIsoCode) {
    translateTextInsideContainers(config.englishIsoCode, lang);
  }
}
